import * as React from "react";
import { AdminNavigation } from "./AdminNavigation";

export const AdminPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <>
            <AdminNavigation />
        </>
    );
};
