import React from "react";
import { WithAuth } from "../components/molecules/Auth/WithAuth";
import { AdminPage } from "../components/pages/AdminPage/AdminPage";

const Page: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <WithAuth adminOnly={true}>
            <AdminPage />
        </WithAuth>
    );
};

export default Page;
